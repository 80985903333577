import React from 'react';
import PropTypes from 'prop-types';

const ProfileImage = ({ src, isRounded }) => (
  <figure className="image">
    <img alt="profile" src={src} style={isRounded ? { radius: '3%' } : {}} />
  </figure>
);

ProfileImage.propTypes = {
  src: PropTypes.string.isRequired,
  isRounded: PropTypes.bool,
};

ProfileImage.defaultProps = {
  isRounded: false,
};

export default ProfileImage;
