import React from 'react';
import Modal from './modal';
import PropTypes from 'prop-types';

const MobileSocialShareModal = ({ isOpen, onClose, socialShare }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <header className="modal-card-head">
      <p className="modal-card-title">Share Page</p>
      <button className="delete" onClick={onClose} aria-label="close"></button>
    </header>
    <section className="modal-card-body">{socialShare}</section>
    <footer className="modal-card-foot"></footer>
  </Modal>
);

MobileSocialShareModal.propTypes = {
  isOpen: PropTypes.boolean,
  onClose: PropTypes.any,
  socialShare: PropTypes.any,
};

export default MobileSocialShareModal;
