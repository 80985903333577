import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  fetchBackgroundImagesArray,
  fetchBackgroundImagesArrayHeroes,
  fetchParticipantProgress,
  fetchPersonalPhotos,
  fetchTopDonors,
  fetchName,
  fetchPersonalPageInfo,
  fetchParticipantAchievements,
  fetchParticipantGifts,
  fetchScreenName,
  fetchTeamCaptainSurveyResponses,
  fetchTeamInfo,
  fetchTeamPageInfo,
  fetchTeamPhoto,
  fetchTeamMembers,
  fetchCompanyInfo,
  fetchTeams,
  fetchCompanyPhoto,
  fetchCompanyPageInfo,
  fetchTopParticipants,
  fetchTeamMemberPersonalPhotos,
  fetchSurveyResponses,
  fetchInteractions,
  fetchEventDetails,
  fetchTeamMemberBoundlessData,
} from '../helpers/requests/fundraising-pages';
import { convertCentsToDollars } from '../helpers/base';
import { fetchUser } from '../helpers/requests/account';
import { fetchDonationForm } from '../helpers/requests/donation';
import { fetchTeamraiserConfig } from '../helpers/requests/event';
import { fetchParticipationTypes, fetchRegistration } from '../helpers/requests/registration';

export const useUser = () => {
  const [data, setData] = useState({ name: {}, email: {} });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchUser();
      if (response.success) {
        setData(response.user);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

//#region Personal

export const usePersonalFundraisingProgress = (eventId, consId) => {
  const [data, setData] = useState({ goal: 0, raised: 0 });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchParticipantProgress({ eventId, consId });
      if (response.success) {
        setData({ goal: response.goal, raised: response.raised });
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [consId, eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const usePersonalPhoto = (eventId, consId) => {
  const [data, setData] = useState({ customUrl: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchPersonalPhotos({ eventId, consId });
      if (response.success) {
        setData(response.images[0]);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [consId, eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const usePersonalTopDonors = (eventId, consId) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTopDonors({ eventId, consId });
      if (response.success) {
        setData(response.donors);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [consId, eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const usePersonalPageInfo = (eventId, consId) => {
  const [data, setData] = useState({ richText: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchPersonalPageInfo({ eventId, consId });
      if (response.success) {
        setData(response.personalPage);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [consId, eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const usePersonalPageGreeting = subject => {
  const [data, setData] = useState({ closed: true, id: null });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    if (!luminateProperties.personalPage.ownPage) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetchInteractions({ subject, pageSize: 1, typeId: process.env.PERSONAL_GREETING_TYPE_ID });
      if (response.success) {
        if (response.interactions.length > 0) {
          const interaction = response.interactions[0];
          setData({ id: interaction.interactionId, closed: interaction.note && interaction.note.text === 'closed' });
        } else {
          setData({ id: null, closed: false });
        }
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [subject]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useName = (eventId, consId) => {
  const [data, setData] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchName({ eventId, consId });
      if (response.success) {
        setData({ firstName: response.firstName, lastName: response.lastName });
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [consId, eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useScreenName = (eventId, consId) => {
  const [data, setData] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchScreenName({ eventId, consId });
      if (response.success) {
        setData(response.screenName);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [consId, eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useParticipantAchievements = (eventId, consId) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchParticipantAchievements({ eventId, consId });
      if (response.success) {
        setData(response);
      } else {
        setError(response);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [consId, eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useParticipantGifts = (eventId, consId) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchParticipantGifts({ eventId, consId });
      if (response.success) {
        setData(response.gifts);
      } else {
        setError(response);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [consId, eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useBackgroundImage = eventId => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchBackgroundImagesArray(eventId);
      if (response.success) {
        setData(response);
      } else {
        setError(response);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useBackgroundImageHeroes = eventId => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchBackgroundImagesArrayHeroes(eventId);
      if (response.success) {
        setData(response);
      } else {
        setError(response);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useSurveyResponses = eventId => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchSurveyResponses(eventId);
      if (response.success) {
        setData(response);
      } else {
        setError(response);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

//#endregion

//#region Team

export const useTeamPhoto = (eventId, teamCaptainConsId) => {
  const [data, setData] = useState({ customUrl: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTeamPhoto({ eventId, consId: teamCaptainConsId });
      if (response.success) {
        setData(response.images[0]);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, teamCaptainConsId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useTeamPageInfo = (eventId, teamId) => {
  const [data, setData] = useState({ richText: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTeamPageInfo({ eventId, teamId });
      if (response.success) {
        setData(response.teamPage);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, teamId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useTeamRoster = (eventId, teamId) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTeamMembers({ eventId, teamId });
      if (response.success) {
        setData(response.participants);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, teamId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useTeamInfo = teamId => {
  const [data, setData] = useState({ name: '', amountRaised: 0, goal: 0, captainConsId: 0 });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTeamInfo({ teamId });
      if (response.success) {
        const team = response.team;
        setData({
          name: team.name,
          amountRaised: parseInt(team.amountRaised),
          goal: parseInt(team.goal),
          captainConsId: parseInt(team.captainConsId),
          numMembers: parseInt(team.numMembers),
          captainFirstName: team.captainFirstName,
          captainLastName: team.captainLastName,
          joinTeamURL: team.joinTeamURL,
          teamDonateURL: team.teamDonateURL,
        });
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [teamId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useTeamMemberPhotos = (eventId, teamRoster) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const teamMembersPhotosArray = [];
      let teamRosterData;
      if (teamRoster.length > 0) {
        teamRosterData = teamRoster
          .sort((a, b) => b.amount - a.amount || a.name.localeCompare(b.name))
          .slice(0, 5)
          .map(participant => {
            teamMembersPhotosArray.push({ consId: participant.consId });
            return {
              buttonClassName: 'is-donate',
              buttonText: 'Donate',
              consId: participant.consId,
              pageLink: participant.link,
              donationLink: participant.donationLink,
              titleLink: participant.link,
              subtitle: `${convertCentsToDollars(participant.amount, { formatted: true, rounded: true })}`,
              title: participant.name,
            };
          });

          const photosPromises = teamMembersPhotosArray.map(async teamMember => await fetchTeamMemberPersonalPhotos(eventId, teamMember.consId));

          const photos = await Promise.all(photosPromises);
          const teamRosterWithPhoto = [];
  
          if (photos.length > 0) {
            teamRosterData.filter(participant => {
              photos.filter(photo => {
                if (photo.images.consId == participant.consId) {
                  teamRosterWithPhoto.push({
                    ...participant,
                    photoUrl: photo.images.photoUrl,
                  });
                }
              });
            });
            const response = {
              success: true,
              message: '',
              images: teamRosterWithPhoto,
            };

          if (response.success) {
            setData(response.images);
          } else {
            setError(response.message);
          }
        }
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, teamRoster]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

//#endregion

//#region Company

export const useCompanyInfo = companyId => {
  const [data, setData] = useState({ name: '', progress: 0, goal: 0, coordinatorId: 0, participantCount: 0, teamCount: 0 });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchCompanyInfo({ companyId });
      if (response.success) {
        const company = response.companies[0];
        setData({
          name: company.name,
          progress: parseInt(company.progress),
          goal: parseInt(company.goal),
          coordinatorId: parseInt(company.coordinatorId),
          participantCount: parseInt(company.participantCount),
          teamCount: parseInt(company.teamCount),
        });
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useEventCompaniesInfo = eventId => {
  const [data, setData] = useState({ count: 0, companies: [] });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchCompanyInfo({ eventId });
      if (response.success) {
        setData({
          count: response.count,
          companies: response.companies,
        });
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useCompanyTeams = (eventId, companyId) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTeams({ eventId, companyId });
      if (response.success) {
        setData(response.teams);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [companyId, eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useCompanyParticipants = (eventId, companyId) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTeams({ eventId, companyId });

      if (response.success) {
        const participants = [];
        const promiseArray = response.teams.map(team => fetchTeamMembers({ eventId, teamId: team.id }));
        const promiseResults = await Promise.all(promiseArray);

        promiseResults.forEach(res => {
          if (res.success) {
            res.participants.forEach(p => participants.push(p));
          }
        });

        setData(participants);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, companyId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useCompanyPhoto = (eventId, companyId, coordinatorId) => {
  const [data, setData] = useState({ customUrl: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchCompanyPhoto({ eventId, companyId, consId: coordinatorId });
      if (response.success) {
        setData(response.images[0]);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, companyId, coordinatorId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useCompanyPageInfo = (eventId, coordinatorId) => {
  const [data, setData] = useState({ richText: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchCompanyPageInfo({ eventId, consId: coordinatorId });
      if (response.success) {
        setData(response.companyPage);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, coordinatorId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

//#endregion

//#region Event

export const useEventTeams = eventId => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTeams({ eventId });
      if (response.success) {
        setData(response.teams);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useTopEventParticipants = eventId => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTopParticipants({ eventId, pageSize: 5 });
      if (response.success) {
        setData(response.participants);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useTeamCaptainSurveyResponses = (eventId, consId) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTeamCaptainSurveyResponses({ eventId, consId });
      if (response.success) {
        setData(response.surveyResponses);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, consId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useEventInfo = (eventName, eventType, publicEventType) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchEventDetails({ eventName, eventType, publicEventType });
      if (response.success) {
        setData(response.eventResponses);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventName, eventType, publicEventType]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

//#endregion

//#region General

export const useDonationForm = (formId, eventId) => {
  const [data, setData] = useState({ donationLevels: { donationLevel: [] } });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchDonationForm({ formId, eventId });
      if (response.success) {
        setData(response.form);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId, formId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useQuery = () => new URLSearchParams(useLocation().search);

//#endregion

export const useTeamraiserConfig = eventId => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTeamraiserConfig({ eventId });
      if (response.success) {
        setData(response.config);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useRegistration = eventId => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchRegistration({ eventId });
      if (response.success) {
        setData(response.registration);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useParticipationTypes = eventId => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const reload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchParticipationTypes({ eventId });
      if (response.success) {
        setData(response.participationTypes);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { data, error, isLoading, reload };
};

export const useLocale = () => (luminateProperties.user.locale === 'es_US' ? 'es' : 'en');
