import { isMobile } from 'is-mobile';

export const isPageBuilder = () => window.location.href.indexOf('PageBuilderAdmin') !== -1;

// Returns boolean indicating if the user is logged in or not
export const isLoggedIn = () => luminateProperties.user.consId !== '';

export const convertCurrencyToNumber = currency =>
  currency.replace(/[$,]+/g, '').toLocaleString('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

export const convertCentsToDollars = (value, options = {}) => {
  let dollars = 0;

  if (value) {
    const cleansedValue = (value + '').replace(/[^\d.-]/g, '');
    dollars = parseFloat(cleansedValue) / 100;
  }

  if (options.formatted) {
    if (options.rounded) {
      return dollars.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }
    return dollars.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  } else {
    return dollars;
  }
};

export const convertDollarsToCents = (value, options = {}) => {
  let cents = 0;

  if (value) {
    const cleansedValue = (value + '').replace(/[^\d.-]/g, '');
    cents = parseFloat(cleansedValue) * 100;
  }

  if (options.formatted) {
    if (options.rounded) {
      return cents.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }
    return cents.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  } else {
    return cents;
  }
};

// Returns Google Maps URL to search for an address
export const getGoogleMapsUrl = address =>
  `https://www.google.com/maps/place/${encodeURIComponent(
    `${!address.street1 ? '' : `${address.street1}`}${!address.street2 ? '' : `, ${address.street2}`}${!address.city ? '' : `, ${address.city}`}${
      !address.state ? '' : `, ${address.state}`
    }${!address.zip ? '' : ` ${address.zip}`}${!address.country ? '' : `, ${address.country}`}`
  )}`;

export const requireAuthenticatedUser = (loginPage, additionalQueryParams = '') => {
  if (!isLoggedIn() && !isPageBuilder()) {
    // User is not logged in
    window.location.href =
      luminateProperties.secure + `SPageServer?pagename=${loginPage}&nextUrl=${encodeURIComponent(window.location.href)}${additionalQueryParams}`;
  }
};

export const hideElement = element => element && element.classList.add('is-hidden');

export const showElement = element => element && element.classList.remove('is-hidden');

export const isVisible = element => window.getComputedStyle(element).display === 'none';

export const fadeIn = (element, duration = 600) => {
  if (!element) return;
  element.style.opacity = 0;
  showElement(element);
  let last = +new Date();
  const tick = () => {
    element.style.opacity = +element.style.opacity + (new Date() - last) / duration;
    last = +new Date();
    if (+element.style.opacity < 1) {
      if (window.requestAnimationFrame) {
        requestAnimationFrame(tick);
      } else {
        setTimeout(tick, 16);
      }
    }
  };
  tick();
};

export const isLoading = element => {
  if (element) {
    element.classList.add('is-loading', 'is-disabled');
    element.disabled = true;
  }
};

export const isLoaded = element => {
  if (element) {
    element.classList.remove('is-loading', 'is-disabled');
    element.disabled = false;
  }
};

// TODO: Find an alternative for smoothing since IE 8-11 and iOS Safari do no support smooth scrolling
// Defaulting to instant, instead of smooth
export const scrollToElement = element => element && element.scrollIntoView(true);

export const stJudeNoWrap = `<span style="white-space: nowrap;">St. Jude</span>`;

export const isMobileDevice = (() => {
  let hasTouchScreen = false;
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    var mQ = window.matchMedia && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      hasTouchScreen = isMobile();
    }
  }
  return hasTouchScreen;
})();

export const isAndroid = navigator.userAgent.match(/(Android)/i);

export const isiPhone = navigator.userAgent.match(/(iPhone)/i);

export const mapDonationLevels = (donationLink, formDonationLevels, content) => {
  const createDonationLink = levelId => `${donationLink}&set.DonationLevel=${levelId}`;

  return content.map(level => {
    let foundLevel = {
      level_id: 0,
    };

    if (formDonationLevels.length > 0) {
      foundLevel = formDonationLevels.find(l => +l.amount.decimal === level.amount);
      if (!foundLevel) {
        foundLevel = formDonationLevels[0];
      }
    }
    return { ...level, link: createDonationLink(foundLevel.level_id) };
  });
};

export const activateTabs = () => {
  const tabs = document.querySelectorAll('.tabs li');

  if (tabs.length > 0) {
    const tabsContent = document.querySelectorAll('.tab-content');

    const deactvateAllTabs = () => {
      tabs.forEach(tab => {
        tab.classList.remove('is-active');
      });
    };

    const hideTabsContent = () => {
      tabsContent.forEach(tabContent => {
        tabContent.classList.remove('is-active');
      });
    };

    const activateTabsContent = tab => tabsContent[getIndex(tab)].classList.add('is-active');

    const getIndex = el => [...el.parentElement.children].indexOf(el);

    const displayTabClicked = tab => tab.classList.add('is-active');

    tabs.forEach(tab => {
      tab.addEventListener('click', () => {
        deactvateAllTabs();
        hideTabsContent();
        activateTabsContent(tab);
        displayTabClicked(tab);
      });
    });
    tabs[0].click();
  }
};
