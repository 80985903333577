import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Modal = ({ isOpen, children, onClose, isCard }) => {
  const modalClassList = classNames({
    modal: true,
    'is-active': isOpen,
  });

  const modalContentClassList = classNames({
    'modal-card': isCard,
    'modal-content': !isCard,
  });

  return (
    <div className={modalClassList}>
      <div className="modal-background" role="button" aria-hidden onClick={onClose}></div>
      <div className={modalContentClassList}>{children}</div>
      <button className="modal-close is-large" onClick={onClose} aria-label="close"></button>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  isCard: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  isCard: false,
};

export default Modal;
