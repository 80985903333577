import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import classNames from 'classnames';
import { useFormik } from 'formik';
import Modal from './modal';
import Notification from './notification';

const EditRichTextModal = ({ isOpen, onClose, richText, handleSubmit, errorMessage, modalTitle, saveText, cancelText }) => {
  const quillModules = {
    toolbar: [
      [{ color: [] }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      richText: richText,
    },
    onSubmit: async values => {
      await handleSubmit(values);
      formik.setSubmitting(false);
    },
  });

  const classList = classNames({
    button: true,
    'is-link': true,
    'is-loading': formik.isSubmitting,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-card-head">
          <p className="modal-card-title">{modalTitle}</p>
          <button className="delete" onClick={onClose} aria-label="close" />
        </header>
        <section className="modal-card-body">
          <ReactQuill
            theme="snow"
            id="richText"
            name="richText"
            onChange={e => formik.setFieldValue('richText', e)}
            value={formik.values.richText}
            modules={quillModules}
          />
          {errorMessage && <Notification text={errorMessage} isError={true} />}
        </section>
        <footer className="modal-card-foot">
          <button type="submit" className={classList} disabled={formik.isSubmitting}>
            {saveText}
          </button>
          <button type="button" className="button" onClick={onClose}>
            {cancelText}
          </button>
        </footer>
      </form>
    </Modal>
  );
};

EditRichTextModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  richText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  saveText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

EditRichTextModal.defaultProps = {
  modalTitle: 'Change Story',
  saveText: 'Save changes',
  cancelText: 'Cancel',
};

export default EditRichTextModal;
