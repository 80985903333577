import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { isiPhone } from '../helpers/base';

export const SocialShare = ({ title, description, image, message }) => {
  const smsLink = `sms:${isiPhone ? `&` : '?'}body=Help support the kids of St. Jude ${document.location.href}&sms_link_share`;

  const showCopied = () => {
    const $copiedElement = document.querySelectorAll('.copied');

    $copiedElement.forEach(el => {
      if (el.classList.contains('is-hidden')) {
        el.classList.remove('is-hidden');
      }

      setTimeout(() => {
        el.classList.add('is-hidden');
      }, 2000);
    });
  };

  return (
    <div className="janrain-container is-relative">
      <div
        // Do not remove/change this classname - it is how the Janrain SDK knows where to inject the social share buttons
        className="janrainSocialPlaceholder"
        data-janrain-url={document.location.href}
        data-janrain-title={title}
        data-janrain-description={description}
        data-janrain-image={image}
        data-janrain-message={message}
      ></div>
      <div className="social-share">
        <button type="button" className="button sms is-hidden-desktop" onClick={() => (document.location.href = smsLink)} title="Text Message"></button>
        <button
          className="button copy"
          title="Copy Link"
          onClick={() => {
            showCopied();
            copy(`${document.location.href}&copy_link_share`);
          }}
        ></button>
        <span className="copied is-hidden">Copied!</span>
      </div>
    </div>
  );
};

SocialShare.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  message: PropTypes.string,
};

export default SocialShare;
