import React from 'react';
import PropTypes from 'prop-types';

const EventDetailsRow = ({ eventDetails }) => {
  const renderDetailsRow = (event, index) => {
    const textRows = event.textRows;
    const actionButton = event.actionButton;
    const renderActionButton = () => {
      if (actionButton.link === 'no-link') {
        return (
          <div>
            {actionButton.icon && <i className={`icon ${actionButton.icon}`}></i>}
            {actionButton.subText && <span className="sub-text">{actionButton.subText}</span>}
          </div>
        );
      } else {
        return (
          <a href={actionButton.link}>
            {actionButton.icon && <i className={`icon ${actionButton.icon}`}></i>}
            {actionButton.subText && <span className="sub-text">{actionButton.subText}</span>}
          </a>
        );
      }
    };

    return (
      <article className="media event-details-row" key={index}>
        <figure className="media-left">{renderActionButton()}</figure>
        <div className="media-content">
          <div className="content">{textRows.map(row => (row ? <p key={index}>{row}</p> : ''))}</div>
        </div>
      </article>
    );
  };
  return <div className="event-details-container">{eventDetails.map((e, index) => renderDetailsRow(e, index))}</div>;
};

EventDetailsRow.propTypes = {
  eventDetails: PropTypes.any,
};

export default EventDetailsRow;
