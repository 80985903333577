import {
  getBackgroundImagesArray,
  //getBackgroundImagesArrayHeroes,
  getPersonalPageInfo,
  getParticipantProgress,
  getPersonalPhotos,
  getPersonalPageHTML,
  getPersonalVideoUrl,
  getParticipantAchievements,
  getParticipantGifts,
  getTopDonors,
  getTeamPageInfo,
  getTeamPhoto,
  getTeamsByInfo,
  getTeamGifts,
  getShortcut,
  getTeamCaptainSurveyResponses,
  updatePersonalPageInfo,
  uploadPersonalPhoto,
  updateRegistration,
  updateShortcut,
  getParticipants,
  updateTeamInformation,
  updateTeamPageInfo,
  uploadTeamPhoto,
  getCompaniesByInfo,
  getCompanyPageInfo,
  getCompanyPhoto,
  getCompanyTeams,
  uploadCompanyPhoto,
  updateCompanyPageInfo,
  getSurveyResponses,
  logInteraction,
  getUserInteractions,
  updateInteraction as trUpdateInteraction,
  getTeamraisersByInfoEventName,
} from '../../services/luminate';
import { updateLocalCompany } from '../../services/api-connect-wrapper';

//#region Personal

/**
 * Gets interactions for a subject.
 * @param {Object} query - The request properties.
 * @param {string} query.subject - The subject of the interaction.
 * @param {number} query.typeId - The interaction type ID.
 * @param {number} query.pageSize - The response page size.
 */
export const fetchInteractions = async ({ subject, typeId, pageSize }) => {
  const respObj = {
    success: false,
    message: '',
    interactions: [],
  };

  try {
    const response = await getUserInteractions({ subject, typeId, pageSize });
    if (!response.code) {
      respObj.success = true;
      respObj.interactions = response.interaction;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Creates an interaction for a subject.
 * @param {Object} query - The request properties.
 * @param {number} query.typeId - The type of the interaction.
 * @param {string} query.subject - The subject of the interaction.
 * @param {string} query.body - The body of the interaction.
 */
export const createInteraction = async ({ typeId, subject, body }) => {
  const respObj = {
    success: false,
    message: '',
  };

  try {
    const response = await logInteraction({ typeId, subject, body });
    if (!response.code) {
      respObj.success = true;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Creates an interaction for a subject.
 * @param {Object} query - The request properties.
 * @param {number} query.id - The interaction ID.
 * @param {number} query.typeId - The type of the interaction.
 * @param {string} query.subject - The subject of the interaction.
 * @param {string} query.body - The body of the interaction.
 */
export const updateInteraction = async ({ id, typeId, subject, body }) => {
  const respObj = {
    success: false,
    message: '',
  };

  try {
    const response = await trUpdateInteraction({ id, typeId, subject, body });
    if (!response.code) {
      respObj.success = true;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets details for a personal fundraising page.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the participant.
 */
export const fetchPersonalPageInfo = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    personalPage: {},
  };

  try {
    const response = await getPersonalPageInfo(eventId, consId);
    if (!response.code) {
      respObj.success = true;
      respObj.personalPage = {
        title: response.pageTitle,
        richText: typeof response.richText === 'string' ? response.richText : '',
      };
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Updates the participant's personal page title and rich text.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {string} query.pageTitle - The text to update the title.
 * @param {string} query.richText - The text to update the page body.
 */
export const updatePersonalPage = async ({ eventId, richText, pageTitle }) => {
  const respObj = {
    success: false,
    message: '',
  };

  try {
    const response = await updatePersonalPageInfo({ eventId, richText, pageTitle });
    if (!response.code) {
      respObj.success = true;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Updates the participant's goal.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.goal - The goal amount in cents.
 */
export const updatePersonalGoal = async ({ eventId, goal }) => {
  const respObj = {
    success: false,
    message: '',
  };

  try {
    const response = await updateRegistration({ eventId, goal });
    if (!response.code) {
      respObj.success = true;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Updates the participant's screen name.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {string} query.screenName - The new screen name.
 */
export const updateScreenName = async ({ eventId, screenName }) => {
  const respObj = {
    success: false,
    message: '',
  };

  try {
    const response = await updateRegistration({ eventId, screenName });
    if (!response.code) {
      respObj.success = true;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets link for the participant's fundraising page video.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the participant.
 */
export const fetchPersonalVideoUrl = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    videoUrl: '',
  };

  try {
    const response = await getPersonalVideoUrl(eventId, consId);
    if (!response.code) {
      respObj.success = true;
      respObj.videoUrl = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the participant's fundraising page images.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the participant.
 */
export const fetchPersonalPhotos = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    images: [],
  };

  try {
    const response = await getPersonalPhotos(eventId, consId);
    if (!response.code) {
      respObj.success = true;
      respObj.images = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

export const fetchTeamMemberPersonalPhotos = async (eventId, consId) => {
  const respObj = {
    success: false,
    message: '',
    images: [],
  };

  try {
    const response = await getPersonalPageHTML(eventId, consId);
    if (!response.code) {
      respObj.success = true;
      respObj.images = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Updates the participant's photos.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {blob} query.photo - The primary photo file.
 * @param {blob} query.photo2 - The secondary photo file.
 */
export const updatePersonalPhotos = async ({ eventId, photo, photo2 }) => {
  const respObj = {
    success: false,
    message: '',
    images: [],
  };

  try {
    const response = await uploadPersonalPhoto({ eventId, photo, photo2 });
    if (!response.code) {
      respObj.success = true;
      respObj.images = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Updates the participant's URL.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {string} query.shortcut - The URL shortcut.
 */
export const updatePersonalUrl = async ({ eventId, shortcut }) => {
  const respObj = {
    success: false,
    message: '',
    url: {},
  };

  try {
    const response = await updateShortcut(eventId, shortcut);
    if (!response.code) {
      respObj.success = true;
      respObj.images = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the participant's URL.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the constituent.
 */
export const getShortcutUrl = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    url: {},
  };

  try {
    const response = await getShortcut(eventId, consId);
    if (!response.code) {
      respObj.success = true;
      respObj.images = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the participant's top donor list.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the participant.
 */
export const fetchTopDonors = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    donors: [],
  };

  try {
    const response = await getTopDonors(eventId, consId, 9999);
    if (Array.isArray(response)) {
      respObj.success = true;
      respObj.donors = response.map(donor => ({ name: donor.name, amount: donor.amount }));
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

export const fetchParticipantAchievements = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    achievements: null,
  };

  try {
    const response = await getParticipantAchievements(eventId, consId);
    if (response.successResponse) {
      respObj.success = true;
      respObj.achievements = response.successResponse.response;
    } else {
      respObj.message = 'error';
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

export const fetchBackgroundImagesArray = async eventId => {
  const respObj = {
    success: false,
    message: '',
    images: null,
  };

  try {
    const response = await getBackgroundImagesArray(eventId);
    if (response.successResponse) {
      respObj.success = true;
      respObj.images = response.successResponse.response.images;
    } else {
      respObj.message = 'error';
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

export const fetchBackgroundImagesArrayHeroes = async eventId => {
  const respObj = {
    success: false,
    message: '',
    images: null,
  };

  try {
    const response = await getBackgroundImagesArrayHeroes(eventId);
    if (response.successResponse) {
      respObj.success = true;
      respObj.images = response.successResponse.response;
    } else {
      respObj.message = 'error';
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

export const fetchSurveyResponses = async eventId => {
  const respObj = {
    success: false,
    message: '',
    surveyResponses: null,
  };

  try {
    const response = await getSurveyResponses(eventId);
    if (response) {
      respObj.success = true;
      respObj.surveyResponses = response;
    } else {
      respObj.message = 'error';
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

export const fetchEventDetails = async ({eventName, eventType, publicEventType}) => {
  const respObj = {
    success: false,
    message: '',
    eventResponses: null,
  };

  try {
    const response = await getTeamraisersByInfoEventName(eventName, eventType, publicEventType);
    if (response) {
      respObj.success = true;
      respObj.eventResponses = response;
      
    } else {
      respObj.message = 'error';
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

export const fetchParticipantGifts = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    gifts: null,
  };

  try {
    const response = await getParticipantGifts(eventId, consId);
    if (Array.isArray(response)) {
      respObj.success = true;
      respObj.gifts = response.map(gift => ({ name: `${gift.name.first} ${gift.name.last}`, amount: gift.giftAmount }));
    } else {
      respObj.message = 'error';
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};
/**
 * Gets the participant's fundraising progress and goal images.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the participant.
 */
export const fetchParticipantProgress = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    goal: 0,
    raised: 0,
  };

  try {
    const response = await getParticipantProgress(eventId, consId);
    if (!response.code) {
      respObj.success = true;
      respObj.goal = +response.personalProgress.goal;
      respObj.raised = +response.personalProgress.raised;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the participant's survey responses.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the participant.
 * @param {number} query.limit - The number of donors to return.
 */
export const fetchTeamCaptainSurveyResponses = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    surveyResponses: null,
  };

  try {
    const response = await getTeamCaptainSurveyResponses(eventId, consId);
    if (response.successResponse) {
      respObj.success = true;
      respObj.surveyResponses = response.successResponse.response.personalEvent;
    } else {
      respObj.message = 'error';
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};
//#endregion

//#region Team

/**
 * Gets details for team fundraising page.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the participant.
 * @param {number} query.teamId - The ID of the team.
 */
export const fetchTeamPageInfo = async ({ eventId, teamId }) => {
  const respObj = {
    success: false,
    message: '',
    teamPage: {},
  };

  try {
    const response = await getTeamPageInfo(eventId, teamId);
    if (!response.code) {
      respObj.success = true;
      respObj.teamPage = {
        title: response.pageTitle,
        richText: response.richText,
      };
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets team participant information.
 * @param {Object} query - The request properties.
 * @param {number} query.teamId - The ID of the team.
 */
export const fetchTeamInfo = async ({ teamId }) => {
  const respObj = {
    success: false,
    message: '',
    team: {},
  };

  try {
    const response = await getTeamsByInfo({ teamId });
    if (!response.code) {
      respObj.success = true;
      if (Array.isArray(response.team) && response.team.length > 0) {
        const team = response.team[0];
        respObj.team = {
          name: team.name,
          numMembers: team.numMembers,
          goal: +team.goal,
          amountRaised: +team.amountRaised,
          captainConsId: team.captainConsId,
          captainFirstName: team.captainFirstName,
          captainLastName: team.captainLastName,
          joinTeamURL: team.joinTeamURL,
          teamDonateURL: team.teamDonateURL,
        };
      }
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the team's fundraising photo.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the team captain.
 */
export const fetchTeamPhoto = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    images: [],
  };

  try {
    const response = await getTeamPhoto(eventId, consId);
    if (!response.code) {
      respObj.success = true;
      respObj.images = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the team's top donor list.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.companyId - The ID of the company.
 */
export const fetchTeams = async ({ eventId, companyId }) => {
  const respObj = {
    success: false,
    message: '',
    teams: [],
  };

  try {
    const response = await getTeamsByInfo({ eventId, companyId, listSortColumn: 'total', listAscending: false });
    if (!response.code) {
      respObj.success = true;
      respObj.teams = response.team.map(team => ({
        id: +team.id,
        name: team.name,
        amount: +team.amountRaised,
        captainConsId: +team.captainConsId,
        link: team.teamPageURL,
      }));
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the team's Total Number of Gifts.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the participant.
 */
export const fetchTeamGifts = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    numGifts: 0,
  };

  try {
    const response = await getTeamGifts(eventId, consId);
    if (!response.code) {
      respObj.success = true;
      respObj.numGifts = response.gift.totalNumberResults;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the team's participant fundraising results.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The name of the team.
 */
export const fetchTeamMembers = async ({ eventId, teamId }) => {
  const respObj = {
    success: false,
    message: '',
    participants: [],
  };

  try {
    const response = await getParticipants({ eventId, firstName: '%%%', listFilterColumn: 'reg.team_id', listFilterText: teamId, pageSize: '2000' });
    if (!response.code) {
      respObj.success = true;
      const participants = response.participant
        .filter(participant => participant.name.isAnonymous === 'false')
        .map(participant => ({
          name: participant.name.screenname || `${participant.name.first} ${participant.name.last}`,
          amount: +participant.amountRaised,
          link: participant.personalPageUrl,
          donationLink: participant.donationUrl,
          consId: participant.consId,
        }));
      respObj.participants = participants;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the event's top participants.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 */
export const fetchTopParticipants = async ({ eventId, pageSize = 5 }) => {
  const respObj = {
    success: false,
    message: '',
    participants: [],
  };

  try {
    const response = await getParticipants({ eventId, firstName: '%%%', listSortColumn: 'total', listAscending: false, pageSize: pageSize });
    if (!response.code) {
      respObj.success = true;
      const participants = response.participant
        .filter(participant => participant.name.isAnonymous === 'false')
        .map(participant => ({
          name: participant.name.screenname || `${participant.name.first} ${participant.name.last}`,
          screenname: participant.name.screenname,
          team: participant.teamName,
          amount: +participant.amountRaised,
          link: participant.personalPageUrl,
          donationLink: participant.donationUrl,
          consId: participant.consId,
        }));
      respObj.participants = participants;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the participant's first and last name results.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The name of the team.
 */
export const fetchName = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    firstName: '',
    lastName: '',
  };

  try {
    const response = await getParticipants({ eventId, firstName: '%%%', listFilterColumn: 'reg.cons_id', listFilterText: consId });
    if (!response.code) {
      if (response.participant.length > 0) {
        respObj.success = true;
        respObj.firstName = response.participant[0].name.first;
        respObj.lastName = response.participant[0].name.last;
      }
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the team's participant fundraising results.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The name of the team.
 */
export const fetchScreenName = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    screenName: '',
  };

  try {
    const response = await getParticipants({ eventId, firstName: '%%%', listFilterColumn: 'reg.cons_id', listFilterText: consId });
    if (!response.code) {
      if (response.participant.length > 0) {
        respObj.success = true;
        respObj.screenName = response.participant[0].name.screenname;
      }
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Update team information if the logged in constituent is a captain.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {string} query.teamName - The name of the team.
 * @param {number} query.teamGoal - The team goal in cents.
 */
export const updateTeam = async ({ eventId, teamName, teamGoal }) => {
  const respObj = {
    success: false,
    message: '',
    team: {},
  };

  try {
    const response = await updateTeamInformation({ eventId, teamName, teamGoal });
    if (!response.code) {
      respObj.success = true;
      respObj.team = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Updates the team's photos.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {Object} query.photo - The primary photo file.
 */
export const updateTeamPhoto = async ({ eventId, photo }) => {
  const respObj = {
    success: false,
    message: '',
    images: [],
  };

  try {
    const response = await uploadTeamPhoto({ eventId, photo });
    if (!response.code) {
      respObj.success = true;
      respObj.images = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Updates the team's page rich text.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {string} query.richText - The text to update the page body.
 */
export const updateTeamPage = async ({ eventId, richText }) => {
  const respObj = {
    success: false,
    message: '',
  };

  try {
    const response = await updateTeamPageInfo({ eventId, richText });
    if (!response.code) {
      respObj.success = true;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the company information.
 * @param {Object} query - The request properties.
 * @param {number} query.companyId - The ID of the company.
 * @param {number} query.eventId - The ID of the event.
 */
export const fetchCompanyInfo = async ({ companyId, eventId }) => {
  const respObj = {
    success: false,
    message: '',
    companies: [],
    count: 0,
  };

  try {
    const response = await getCompaniesByInfo({ companyId, frId: eventId });
    if (!response.code) {
      respObj.success = true;
      if (response.company.length > 0) {
        respObj.companies = response.company.map(c => ({
          name: c.companyName,
          progress: +c.amountRaised,
          participantCount: +c.participantCount,
          teamCount: +c.teamCount,
          goal: +c.goal,
          coordinatorId: +c.coordinatorId,
        }));
        respObj.count = response.totalNumberResults;
      }
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets details for company fundraising page.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.consId - The ID of the company coordinator.
 */
export const fetchCompanyPageInfo = async ({ eventId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    companyPage: {},
  };

  try {
    const response = await getCompanyPageInfo(eventId, consId);
    if (!response.code) {
      respObj.success = true;
      respObj.companyPage = {
        title: response.pageTitle,
        richText: response.richText,
      };
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the company's fundraising photo.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.companyId - The ID of the company.
 * @param {number} query.consId - The ID of the company coordinator.
 */
export const fetchCompanyPhoto = async ({ eventId, companyId, consId }) => {
  const respObj = {
    success: false,
    message: '',
    images: [],
  };

  try {
    const response = await getCompanyPhoto(eventId, companyId, consId);
    if (!response.code) {
      respObj.success = true;
      respObj.images = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets the company's teams.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.companyId - The ID of the company.
 */
export const fetchCompanyTeams = async ({ eventId, companyId }) => {
  const respObj = {
    success: false,
    message: '',
    teams: [],
  };

  try {
    const response = await getCompanyTeams({ eventId, companyId });
    if (!response.code) {
      respObj.success = true;
      respObj.teams = response.map(team => ({
        id: +team.teamId,
        name: team.teamName,
        amount: +team.amount,
      }));
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Update company information if the logged in constituent is the coordinator.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.companyId - The ID of the company.
 * @param {string} query.companyName - The name of the team.
 * @param {number} query.companyGoal - The team goal in cents.
 */
export const updateCompany = async ({ eventId, companyId, companyName, companyGoal }) => {
  const respObj = {
    success: false,
    message: '',
    team: {},
  };

  try {
    const response = await updateLocalCompany({ eventId, companyId, companyName, companyFundraisingGoal: companyGoal });
    if (!response.code) {
      respObj.success = true;
      respObj.team = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Updates the company's photo.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.companyId - The ID of the company.
 * @param {Object} query.photo - The primary photo file.
 */
export const updateCompanyPhoto = async ({ eventId, companyId, photo }) => {
  const respObj = {
    success: false,
    message: '',
    images: [],
  };

  try {
    const response = await uploadCompanyPhoto({ eventId, companyId, photo });
    if (!response.code) {
      respObj.success = true;
      respObj.images = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Updates the company's page rich text.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 * @param {number} query.companyId - The ID of the company.
 * @param {string} query.richText - The text to update the page body.
 */
export const updateCompanyPage = async ({ eventId, companyId, richText }) => {
  const respObj = {
    success: false,
    message: '',
  };

  try {
    const response = await updateCompanyPageInfo({ eventId, companyId, richText });
    if (!response.code) {
      respObj.success = true;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};
//#endregion
