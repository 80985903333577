import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const PageTitle = ({ title, firstSubtitle, secondSubtitle, editButton }) => (
  <div>
    <h1 className="title has-text-inherit">
      {title} {editButton && editButton}
    </h1>
    {firstSubtitle && (
      <p className="subtitle has-text-inherit mb-1">
        <span>{firstSubtitle}</span>
        {secondSubtitle && (
          <>
            <span>{` ${String.fromCharCode(8226)} `}</span>
            <span>{ReactHtmlParser(secondSubtitle)}</span>
          </>
        )}
      </p>
    )}
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  firstSubtitle: PropTypes.string,
  secondSubtitle: PropTypes.string,
  editButton: PropTypes.any,
};

export default PageTitle;
