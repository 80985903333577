import { getParticipationTypes, getTeamraisersByInfo, getTeamraiserConfig } from '../../services/luminate';
import { getParticipantRegistrations } from './registration';

// get event IDs for this program that are accepting registrations
export const getEventsAcceptingRegistrations = async (program, publicEventType) => {
  const responseData = await getTeamraisersByInfo(program, '%%%', publicEventType);
  if (Array.isArray(responseData) && responseData.length > 0) {
    return responseData
      .filter(event => event.accepting_registrations === 'true')
      .map(event => parseInt(event.id))
      .sort();
  }

  return [];
};

// get all active participation types for the selected event to get waiver text
export const getActiveParticipationTypes = async frId => {
  const responseData = await getParticipationTypes({ eventId: frId });

  if (Array.isArray(responseData.participationType) && responseData.participationType.length > 0) {
    return responseData.participationType.filter(partType => partType.active === 'true');
  }

  return [];
};

// gets the earliest created event for a program that a constituent is not registered for
export const getFirstAvailableEvent = async (program, publicEventType) => {
  const eventsPromise = getEventsAcceptingRegistrations(program, publicEventType);
  const participantsPromise = getParticipantRegistrations(program, publicEventType);

  const [events, registrations] = await Promise.all([eventsPromise, participantsPromise]);

  // There are no open events to register for
  if (events.length === 0) {
    return undefined;
  }

  // Filter out events that are already registered
  // Sort by oldest to newest
  const availableEvents = events.filter(event => !registrations.some(registration => registration === event)).sort();

  // If availableEvents has a length of 0 then the participant is registered
  // for all DIY events
  // Should they be directed to the survey with an explanation
  if (availableEvents.length === 0) {
    return undefined;
  }

  return availableEvents[0];
};

/**
 * Gets event configuration details.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 */
export const fetchTeamraiserConfig = async ({ eventId }) => {
  const respObj = {
    success: false,
    message: '',
    config: {},
  };

  try {
    const response = await getTeamraiserConfig(eventId);
    if (!response.code) {
      respObj.success = true;
      respObj.config = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};
