import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import Modal from './modal';
import Notification from './notification';

const EditEventDetailsModal = ({ isOpen, onClose, surveyData, handleSubmit, errorMessage, modalTitle }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign(...surveyData.map(data => ({ [data.key]: data.value }))),
    onSubmit: async values => {
      await handleSubmit(values, surveyData);
      formik.setSubmitting(false);
    },
  });

  const classList = classNames({
    button: true,
    'is-link': true,
    'is-loading': formik.isSubmitting,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-card-head">
          <p className="modal-card-title">{modalTitle}</p>
          <button className="delete" onClick={onClose} aria-label="close"></button>
        </header>
        <section className="modal-card-body edit-event-details">
          {surveyData.map(field => (
            <div className="field" key={field.key}>
              <div className="control">
                <label htmlFor={field.key}>{field.questionText}</label>
                <input
                  id={field.key}
                  className={`input ${formik.touched[field.key] && formik.errors[field.key] ? 'is-danger' : ''}`}
                  type="text"
                  name={field.key}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[field.key]}
                  aria-label={field.key}
                />
              </div>
              {formik.touched[field.key] && formik.errors[field.key] && <p className="help is-danger">{formik.errors[field.key]}</p>}
            </div>
          ))}

          {errorMessage && <Notification text={errorMessage} isError={true} />}
        </section>
        <footer className="modal-card-foot">
          <button type="submit" className={classList} disabled={formik.isSubmitting}>
            Save changes
          </button>
          <button type="button" className="button" onClick={onClose}>
            Cancel
          </button>
        </footer>
      </form>
    </Modal>
  );
};

EditEventDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  surveyData: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

EditEventDetailsModal.defaultProps = {
  modalTitle: 'Change Event Details',
};

export default EditEventDetailsModal;
