import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TopList = ({ items, isCard, showLink }) => {
  const classList = classNames({
    box: isCard,
  });

  const listItem = ({ title, subtitle, link, buttonText, photoUrl, titleLink, buttonClassName }, index) => (
    <div className="top-list-row" key={index}>
      <div className="columns is-vcentered is-mobile is-centered">
        <div className="column is-narrow">
          {photoUrl ? (
            <span className="rounded-img" style={{ backgroundImage: `url(${photoUrl})` }}></span>
          ) : (
            <span className="tag is-large is-round is-success">{index + 1}</span>
          )}
        </div>
        <div className="column">
          {titleLink ? (
            <a href={titleLink} className="has-text-weight-bold">
              {title}
            </a>
          ) : (
            <p className="has-text-weight-bold">{title}</p>
          )}
          <p>{subtitle}</p>
        </div>
        {showLink &&
          (buttonText ? (
            <div className="column is-narrow">
              <a href={link} className={`button is-link is-outlined is-small is-narrow ${buttonClassName ? buttonClassName : ''}`}>
                {buttonText}
              </a>
            </div>
          ) : (
            <div className="column is-narrow">
              <a href={link} className="button is-white">
                <span className="icon is-small">
                  <i className="svg-icon right-arrow-icon is-info"></i>
                </span>
              </a>
            </div>
          ))}
      </div>
      {index < items.length - 1 ? <hr /> : ''}
    </div>
  );

  return <div className={classList}>{items.map((item, index) => listItem(item, index))}</div>;
};

TopList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      link: PropTypes.string,
      buttonText: PropTypes.string,
      photoUrl: PropTypes.string,
      consId: PropTypes.string,
    })
  ).isRequired,
  showLink: PropTypes.bool,
  isCard: PropTypes.bool,
};

TopList.defaultProps = {
  showLink: false,
  isCard: false,
};

export default TopList;
