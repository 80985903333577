import { getDonationFormInfo } from '../../services/luminate';

/**
 * Get donation form elements
 * @param {Object} query - The user's user name.
 * @param {number} password - The form's ID.
 * @param {number} password - The event's ID.
 */
export const fetchDonationForm = async ({ formId, eventId }) => {
  const respObj = {
    success: false,
    message: '',
    form: null,
  };

  try {
    const response = await getDonationFormInfo({ formId, eventId });
    if (!response.code) {
      respObj.success = true;
      respObj.form = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};
