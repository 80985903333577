import { fetchResource, convertToArray } from '../helpers/api';

export const getLocalCompany = async companyId => {
  const formData = new URLSearchParams();
  formData.append('company_id', companyId);

  return request('getLocalCompany', formData).then(responseData => {
    if (responseData.getLocalCompanyResponse) {
      responseData.getLocalCompanyResponse.company.id = companyId;
      return responseData.getLocalCompanyResponse.company;
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};

export const getOrganizations = async searchParams => {
  const formData = new URLSearchParams();
  if (searchParams.name) {
    formData.append('name', searchParams.name);
  }
  if (searchParams.listPageSize) {
    formData.append('list_page_size', searchParams.listPageSize);
  }
  if (searchParams.listPageOffset) {
    formData.append('list_page_offset', searchParams.listPageOffset);
  }
  if (searchParams.listAscending) {
    formData.append('list_ascending', searchParams.listAscending);
  }
  if (searchParams.listSortColumn) {
    formData.append('list_sort_column', searchParams.listSortColumn);
  }

  return request('listOrganizations', formData).then(responseData => {
    if (responseData.listOrganizationsResponse) {
      responseData.listOrganizationsResponse.organization = convertToArray(responseData.listOrganizationsResponse.organization);
      return responseData.listOrganizationsResponse.organization;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const addLocalCompany = async company => {
  const formData = new URLSearchParams();
  formData.append('fr_id', company.frId);
  formData.append('org_id', company.orgId);
  formData.append('company_name', company.name);
  formData.append('custom_company_detail_1', company.city);
  formData.append('custom_company_detail_2', company.state);
  if (company.coordinatorId) formData.append('company_coordinator_cons_id', company.coordinatorId);

  return request('addLocalCompany', formData).then(responseData => {
    if (responseData.addLocalCompanyResponse) {
      return responseData.addLocalCompanyResponse;
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};

export const updateLocalCompany = async ({ eventId, companyId, companyName, companyFundraisingGoal }) => {
  const formData = new URLSearchParams();
  formData.append('fr_id', eventId);
  formData.append('company_id', companyId);
  if (companyName) formData.append('company_name', companyName);
  if (companyFundraisingGoal) formData.append('company_fundraising_goal', companyFundraisingGoal);

  return request('updateLocalCompany', formData).then(responseData => {
    if (responseData.updateLocalCompanyResponse) {
      return responseData.updateLocalCompanyResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const addOrganization = async organization => {
  const orgPayload = `<organizationRequest xmlns="http://convio.com/crm/v1.0"><organization><name>${organization.name}</name><address><address_type_id>1</address_type_id><street1>${organization.street1}</street1><street2>${organization.street2}</street2><city>${organization.city}</city><state_province>${organization.state}</state_province><postal_code>${organization.postalCode}</postal_code><country>${organization.country}</country></address></organization></organizationRequest>`;

  const formData = new URLSearchParams();
  formData.append('organization', orgPayload);

  return request('addOrganization', formData).then(responseData => {
    if (responseData.getOrganizationResponse) {
      return responseData.getOrganizationResponse.organization;
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};

export const setOrganizationParent = async (parentOrgId, orgId) => {
  const formData = new URLSearchParams();
  formData.append('parent_org_id', parentOrgId);
  formData.append('org_id', orgId);

  return request('setOrganizationParent', formData).then(responseData => {
    if (responseData.getOrganizationParentResponse) {
      return responseData.getOrganizationParentResponse.hierarchy;
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};

const request = async (endpoint, formData) => {
  if (!formData.has('response_format')) {
    formData.append('response_format', 'json');
  }
  formData.append('suppress_response_codes', 'true');

  const path = `${process.env.API_CONNECT_WRAPPER_URL}${endpoint}`;

  return fetchResource(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-ibm-client-id': process.env.API_CONNECT_WRAPPER_CLIENT_ID,
    },
    body: formData,
  });
};
