import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const DonationPills = ({ donationLevels, clickHandler, selectedId, sectionTopText }) => {
  const [isOtherAmountSelected, setIsOtherAmountSelected] = useState(false);

  const setActiveLevel = event => {
    const currentActiveLevel = document.querySelector('.donation-pills-container .active');
    if (currentActiveLevel) {
      currentActiveLevel.classList.remove('active');
    }

    const clickedLevelId = event.target.getAttribute('data-level-id');
    const newActiveLevel = document.querySelector(`button[data-level-id="${clickedLevelId}"]`);
    if (newActiveLevel) {
      newActiveLevel.classList.add('active');
    }
  };

  const handleClick = ({ level_id, isOther, amount }, event) => {
    setActiveLevel(event);
    setIsOtherAmountSelected(isOther);
    clickHandler({ level_id, amount });
  };

  const setOtherAmountValue = (event, level_id) => {
    const isOther = true;
    const amount = event.target.value;

    handleClick({ level_id, isOther, amount }, event);
  };

  const renderDonationPill = ({ amount, level_id, userSpecified }, index) => {
    const isOther = userSpecified === 'true';
    const isSelected = selectedId === level_id;

    const classList = classNames({
      button: true,
      'is-secondary': !isSelected,
      'is-other': isOther,
      active: isSelected,
    });

    const amountNumber = amount.decimal.split('.')[0];
    const displayText = isOther ? 'Other' : `$${amountNumber}`;

    return isOtherAmountSelected && isOther ? (
      <input
        key={index}
        id="other_amount"
        className="input"
        type="text"
        name="other_amount"
        placeholder="$5"
        aria-label="donation level"
        onKeyUp={e => setOtherAmountValue(e, level_id)}
      />
    ) : (
      <>
        <button
          key={index}
          className={classList}
          data-level-id={level_id}
          aria-label="donation button"
          onClick={e => handleClick({ level_id, isOther, amount: amountNumber }, e)}
        >
          {displayText}
        </button>
        {index === 2 && <div key={`break_${index}`} className="break"></div>}
      </>
    );
  };

  return donationLevels ? (
    <div className="donation-pills">
      <p>{sectionTopText}</p>
      <div className="donation-pills-container">{donationLevels.map((level, index) => renderDonationPill(level, index))}</div>
    </div>
  ) : null;
};

DonationPills.propTypes = {
  donationLevels: PropTypes.string,
  clickHandler: PropTypes.string,
  selectedId: PropTypes.number,
  sectionTopText: PropTypes.string,
};

export default DonationPills;
