import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import classNames from 'classnames';
import Modal from './modal';
import Notification from './notification';

const EditImageModal = ({ isOpen, onClose, image, isLoading, handleSubmit, errorMessage, modalTitle, saveText, cancelText, selectFileText }) => {
  const [cropper, setCropper] = useState();
  const [file, setFile] = useState(image);

  useEffect(() => {
    setFile(image);
  }, [image]);

  const handleChange = e => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (files && files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        function() {
          setFile(reader.result);
        },
        false
      );

      reader.readAsDataURL(files[0]);
    }
  };

  const classList = classNames({
    button: true,
    'is-link': true,
    'is-loading': isLoading,
  });

  const getCanvas = () => {
    cropper.getCroppedCanvas({ maxHeight: 300, maxWidth: 300 }).toBlob(blob => {
      handleSubmit(blob);
    }, 'image/jpeg');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <>
        <header className="modal-card-head">
          <p className="modal-card-title">{modalTitle}</p>
          <button className="delete" onClick={onClose} aria-label="close" />
        </header>
        <section className="modal-card-body">
          <div className="file is-link has-name mb-2">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="resume"
                accept="image/*"
                onChange={handleChange}
                onClick={e => {
                  e.target.value = null;
                }}
              />
              <span className="file-cta">
                <span className="file-label">{selectFileText}</span>
              </span>
            </label>
          </div>
          <Cropper
            initialAspectRatio={1}
            src={file}
            viewMode={1}
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            minCanvasWidth={300}
            minCanvasHeight={300}
            minContainerHeight={300}
            minContainerWidth={300}
            background={false}
            responsive={true}
            checkOrientation={false}
            autoCropArea={1}
            onInitialized={instance => setCropper(instance)}
          />
          <div className="buttons has-addons is-centered mt-2">
            <button className="button is-white" title="Rotate Clockwise" onClick={() => cropper.rotate(90)}>
              <span className="icon">
                <i className="svg-icon rotate-clockwise-icon is-primary" />
              </span>
            </button>
            <button className="button is-white" title="Rotate Counter Clockwise" onClick={() => cropper.rotate(-90)}>
              <span className="icon">
                <i className="svg-icon rotate-counter-clockwise-icon is-primary" />
              </span>
            </button>
            <button className="button is-white" title="Zoom In" onClick={() => cropper.zoom(0.1)}>
              <span className="icon">
                <i className="svg-icon zoom-in-icon is-primary" />
              </span>
            </button>
            <button className="button is-white" title="Zoom Out" onClick={() => cropper.zoom(-0.1)}>
              <span className="icon">
                <i className="svg-icon zoom-out-icon is-primary" />
              </span>
            </button>
            <button className="button is-white" title="Crop Mode" onClick={() => cropper.setDragMode('crop')}>
              <span className="icon">
                <i className="svg-icon expand-icon is-primary" />
              </span>
            </button>
            <button className="button is-white" title="Move Mode" onClick={() => cropper.setDragMode('move')}>
              <span className="icon">
                <i className="svg-icon drag-and-drop-icon is-primary" />
              </span>
            </button>
          </div>
          {errorMessage && <Notification text={errorMessage} isError={true} />}
        </section>
        <footer className="modal-card-foot">
          <button type="button" className={classList} onClick={getCanvas} disabled={isLoading}>
            {saveText}
          </button>
          <button type="button" className="button" onClick={onClose}>
            {cancelText}
          </button>
        </footer>
      </>
    </Modal>
  );
};

EditImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  saveText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  selectFileText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

EditImageModal.defaultProps = {
  modalTitle: 'Change Photo',
  saveText: 'Save changes',
  cancelText: 'Cancel',
  selectFileText: 'Choose a file...',
};

export default EditImageModal;
