import { login, logout, loginTest, getLoginUrl, changePassword, isUsernameAvailable, isEmailValid, create, getUser } from '../../services/luminate';

/**
 * Send user a reminder email of their username/password if their email is on file
 * Returns true if user was logged out and false if there was an error
 * @param {string} userName - The user's user name.
 * @param {string} password - The user's password.
 */
export const loginUser = async ({ userName, password, rememberMe }) => {
  const respObj = {
    success: false,
    message: '',
    consId: null,
  };

  try {
    const response = await login({ userName, password, rememberMe });
    if (!response.code) {
      respObj.success = true;
      respObj.consId = response.cons_id;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Send user a reminder email of their username/password if their email is on file
 * Returns true if user was logged out and false if there was an error
 */
export const fetchUser = async () => {
  const respObj = {
    success: false,
    message: '',
    user: {},
  };

  try {
    const response = await getUser();
    if (!response.code) {
      respObj.success = true;
      respObj.user = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Send user a reminder email of their username/password if their email is on file
 * @param {string} email - The user's email address.
 */
export const sendReminder = async email => {
  const respObj = {
    success: false,
    message: '',
  };

  try {
    const response = await login({ sendUsername: true, email });
    if (!response.code) {
      respObj.success = true;
      respObj.message = response.message;
    } else {
      respObj.message = response.message;
    }
    return respObj;
  } catch (err) {
    respObj.message = 'There was an error sending your account information.';
    return respObj;
  }
};

/**
 * Log user out of TeamRaiser
 * Returns true if user was logged out and false if there was an error
 */
export const logoutUser = async () => {
  try {
    return await logout();
  } catch (err) {
    return false;
  }
};

/**
 * Get user session token
 * Returns auth token if user is logged in or false if not
 */
export const establishSession = async () => {
  try {
    const testResp = await loginTest();
    if (testResp.cons_id) {
      const loginUrlResp = await getLoginUrl();
      return {
        consId: testResp.cons_id,
        token: loginUrlResp.token,
      };
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

/**
 * Send user a reminder email of their username/password if their email is on file
 * Returns true if user was logged out and false if there was an error
 * @param {string} oldPassword - The user's current password.
 * @param {string} newPassword - The user's new password.
 * @param {string} reminderHint - The user's password hint.
 */
export const changeUserPassword = async (oldPassword, newPassword, reminderHint) => {
  try {
    return await changePassword({ oldPassword, newPassword, reminderHint });
  } catch (err) {
    return false;
  }
};

/**
 * create user.
 * @param {Object} user - The user.
 * @param {string} user.firstName - The first name.
 * @param {string} user.lastName - The last name.
 * @param {string} user.email - The email address.
 * @param {string} user.userName - The account user name.
 * @param {string} user.country - The address country.
 * @param {string} user.postalCode - The address postal code.
 * @param {string} user.password - The account password.
 * @param {string} user.emailOptIn - If the user is opted-in to email.
 */
export const createUser = async user => {
  let isEmail = false;
  let isAvailable = false;

  const respObj = {
    success: false,
    message: '',
    consId: null,
  };
  // Check if the provided username is an email address
  isEmail = await isEmailValid(user.userName);
  // If not an email then check if the username is available
  if (!isEmail) {
    const isAvailableResp = await isUsernameAvailable(user.userName);
    // Check if existing user name
    isAvailable = isAvailableResp.available === 'true';

    if (!isAvailable) {
      //isAvailableResp only returns true or false. There is no message included so hard coding one in.
      //respObj.message = isAvailableResp.message;
      respObj.message = 'A user with this information already exists.'
    }
  }

  // If the username is an email or available then try to create
  if (isEmail || isAvailable) {
    const response = await create(user);
    // The user already exists
    if (response.code === '11') {
      respObj.message = 'A user with this information already exists.';
    }
    // There was some other error
    else if (response.code) {
      respObj.message = response.message;
    }
    // Constituent was created
    else if (response.cons_id) {
      respObj.consId = response.cons_id;
      respObj.success = true;
    }
  }

  return respObj;
};
