import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from './modal';
import Notification from './notification';

const EditTitleModal = ({ isOpen, onClose, title, handleSubmit, errorMessage, modalTitle, saveText, cancelText }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: title,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .max(50, ({ max }) => `Title must be ${max} characters or less`)
        .required('Title is required'),
    }),
    onSubmit: async values => {
      await handleSubmit(values);
      formik.setSubmitting(false);
    },
  });

  const classList = classNames({
    button: true,
    'is-link': true,
    'is-loading': formik.isSubmitting,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-card-head">
          <p className="modal-card-title">{modalTitle}</p>
          <button className="delete" onClick={onClose} aria-label="close" />
        </header>
        <section className="modal-card-body">
          <div className="field">
            <div className="control">
              <input
                id="title"
                className={`input ${formik.touched.title && formik.errors.title ? 'is-danger' : ''}`}
                type="text"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                aria-label="title"
                required
              />
            </div>
            {formik.touched.title && formik.errors.title && <p className="help is-danger">{formik.errors.title}</p>}
          </div>

          {errorMessage && <Notification text={errorMessage} isError={true} />}
        </section>
        <footer className="modal-card-foot">
          <button type="submit" className={classList} disabled={formik.isSubmitting}>
            {saveText}
          </button>
          <button type="button" className="button" onClick={onClose}>
            {cancelText}
          </button>
        </footer>
      </form>
    </Modal>
  );
};

EditTitleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  saveText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

EditTitleModal.defaultProps = {
  modalTitle: 'Change Title',
  saveText: 'Save changes',
  cancelText: 'Cancel',
};

export default EditTitleModal;
