import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from './modal';
import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min.js';

const EditBackgroundImageModal = ({ isOpen, onClose, bgImageData, image, isLoading, handleSubmit }) => {
  const [bgImage, setBgImage] = useState(image);

  useEffect(() => {
    setBgImage(image);
  }, [image]);

  const handleSave = e => {
    e.preventDefault();
    handleSubmit(bgImage);
  };

  const classList = classNames({
    button: true,
    'is-primary': true,
    'is-loading': isLoading,
  });

  const greetingCarousel = () => {
    bulmaCarousel.attach('.background-image-selector', {
      slidesToShow: 2,
      navigation: true,
      pagination: false,
      breakpoints: [
        { changePoint: 640, slidesToShow: 2, slidesToScroll: 2 },
        { changePoint: 768, slidesToShow: 2, slidesToScroll: 2 },
      ],
    });
  };

  setTimeout(() => {
    if (bgImageData.length > 0) {
      greetingCarousel();
    }
  }, 0);
  /* eslint-disable */
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <>
        <header className="modal-card-head background-image-header">
          <p className="modal-card-title">
            <span className="header-title">
              <i className="picture-icon"></i> Edit Background
            </span>
            <span className="smaller">Choose your favorite background image. This will display on your fundraising page and in your fundraising center.</span>
          </p>
          <button className="delete-button" onClick={onClose} aria-label="close">
           Close <i></i>
          </button>
        </header>
        <section className="modal-card-body background-image-body">
          <div className="background-image-selector">
            {bgImageData.map((img, i) => (
              <div key={i} className={`item-${i}`}>
                <label
                  htmlFor="background-image"
                  key={`label_${i}`}
                  aria-label="background image"
                  className={bgImage === img ? 'selected' : ''}
                  onClick={() => {
                    setBgImage(img);
                  }}
                  style={{ backgroundImage: `url('${img}')` }}
                >
                  <span className="is-sr-only">background image choice</span>
                </label>
                {bgImage === img ? (
                  <p key={`p_${i}`}>
                    <i key={`i_${i}`}></i> <span key={`span_${i}`}>Selected</span>
                  </p>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
        </section>
        <footer className="modal-card-foot background-image-footer">
          <button type="button" className={classList} onClick={handleSave} disabled={isLoading}>
            Save
          </button>
          <button className="close-button" onClick={onClose}>Cancel</button>
        </footer>
      </>
    </Modal>
  );
};
/* eslint-enable */
EditBackgroundImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bgImageData: PropTypes.any,
  image: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EditBackgroundImageModal;
