import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Notification = ({ isError, text }) => {
  const classList = classNames({
    notification: true,
    'is-size-7': true,
    'is-light': true,
    'is-danger': isError,
    'is-success': !isError,
  });

  return <div className={classList}>{text}</div>;
};

Notification.propTypes = {
  isError: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

Notification.defaultProps = {
  isError: false,
};

export default Notification;
