import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { convertCentsToDollars } from '../helpers/base';

const ProgressBar = ({ goal, progress, showIndicator, showAmounts, raisedText, goalText, isCard, editButton }) => {
  const classList = classNames({
    'progress-container': true,
    box: isCard,
  });

  const progressPercent = Math.min(Math.floor(goal !== 0 ? (+progress / +goal) * 100 : 100), 100);

  const amountLevel = (
    <div className="level is-mobile">
      <div className="level-left">
        <div className="level-item has-text-left">
          <div>
            <label id="amount-raised" className="text-raised">
              {raisedText}
              <span aria-labelledby="amount-raised" className="amount-raised is-block">{`${convertCentsToDollars(Math.floor(progress), {
                formatted: true,
                rounded: true,
              })}`}</span>
            </label>
          </div>
        </div>
      </div>
      <div className="level-right">
        <div className="level-item has-text-right">
          <div>
            <label id="amount-goal" className="text-goal">
              {goalText}
              <span aria-labelledby="amount-goal" className="amount-goal is-block">
                {editButton && editButton}
                {`${convertCentsToDollars(Math.floor(goal), {
                  formatted: true,
                  rounded: true,
                })}`}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );

  const indicator = (
    <label
      aria-label="fundraising progress percentage"
      className="progress-indicator tag has-text-centered has-text-white"
      style={{ left: `${progressPercent === 0 ? 1 : progressPercent === 100 ? 98 : progressPercent}%` }}
    >
      {progressPercent}%
    </label>
  );

  return (
    <div className={classList}>
      {showIndicator && indicator}
      <progress className="progress is-primary is-medium" value={progress} max={goal}>
        {progressPercent}%
      </progress>
      {showAmounts && amountLevel}
    </div>
  );
};

ProgressBar.propTypes = {
  goal: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  showIndicator: PropTypes.bool,
  showAmounts: PropTypes.bool,
  raisedText: PropTypes.string,
  goalText: PropTypes.string,
  isCard: PropTypes.bool,
  editButton: PropTypes.any,
};

ProgressBar.defaultProps = {
  showIndicator: true,
  showAmounts: true,
  raisedText: 'Amount raised:',
  goalText: 'My goal:',
  isCard: false,
};

export default ProgressBar;
