import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from './modal';
import Notification from './notification';

const EditGoalModal = ({ isOpen, onClose, goal, handleSubmit, errorMessage, modalTitle, saveText, cancelText }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      goal: goal,
    },
    validationSchema: Yup.object({
      goal: Yup.number()
        .min(1, ({ min }) => `Goal must be more than ${min}`)
        .max(999999999, ({ max }) => `Goal must be less than ${max}`)
        .truncate()
        .required('Goal is required'),
    }),
    onSubmit: async values => {
      await handleSubmit(values);
      formik.setSubmitting(false);
    },
  });

  const classList = classNames({
    button: true,
    'is-link': true,
    'is-loading': formik.isSubmitting,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <header className="modal-card-head">
          <p className="modal-card-title">{modalTitle}</p>
          <button className="delete" onClick={onClose} aria-label="close" />
        </header>
        <section className="modal-card-body">
          <div className="field">
            <div className="control has-icons-left has-icons-right">
              <input
                id="goal"
                className={`input ${formik.touched.goal && formik.errors.goal ? 'is-danger' : ''}`}
                type="number"
                name="goal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.goal}
                aria-label="goal"
                required
              />
              <span className="icon is-small is-left">$</span>
              <span className="icon is-small is-right">.00</span>
            </div>
            {formik.touched.goal && formik.errors.goal && <p className="help is-danger">{formik.errors.goal}</p>}
          </div>
          {errorMessage && <Notification text={errorMessage} isError={true} />}
        </section>
        <footer className="modal-card-foot">
          <button type="submit" className={classList} disabled={formik.isSubmitting}>
            {saveText}
          </button>
          <button type="button" className="button" onClick={onClose}>
            {cancelText}
          </button>
        </footer>
      </form>
    </Modal>
  );
};

EditGoalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  goal: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  saveText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

EditGoalModal.defaultProps = {
  modalTitle: 'Change Goal',
  saveText: 'Save changes',
  cancelText: 'Cancel',
};

export default EditGoalModal;
